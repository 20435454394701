import React, { useState, useEffect } from 'react';

const FirstTimeModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('hasSeenMapModal');
    if (!hasSeenModal) {
      setIsOpen(true);
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    sessionStorage.setItem('hasSeenMapModal', 'true');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[10000] flex items-center justify-center">
      <div className="relative p-5 border w-[32rem] max-w-[90%] shadow-lg rounded-md bg-white max-h-[90vh] flex flex-col">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="mt-3 flex-grow overflow-y-auto">
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-center sm:text-xl sm:leading-none md:text-2xl">
            <span className="inline-block sm:block">Welcome to the new</span>{' '}
            <span className="inline-block sm:block">AltGrocery 2.0 Demo</span>
          </h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500 mb-4 text-center">
              We're excited to have you here! 🎉 Use the map to explore local indie grocers, farmers' markets, and unique food spots in your province or city. Our new filters make it even easier to find exactly what you're looking for.
            </p>
            <p className="text-sm text-gray-500 font-semibold mb-2 text-left">What's New?</p>
            <ul className="text-sm text-gray-500 text-left list-disc pl-5 mb-4">
              <li>Faster load times and a responsive design for a smoother experience on both desktop and mobile devices.</li>
              <li>Quick and easy searches using interactive filters tailored to your preferences.</li>
              <li>New sharing options to post listings on social media or generate a custom QR code.</li>
              <li>Visit the Roadmap page, to see what's coming soon and suggest new features.</li>
            </ul>
            <p className="text-sm text-gray-500 font-semibold mb-2 text-left">What's Coming Soon? (Upon Full 2.0 Launch)</p>
            <ul className="text-sm text-gray-500 text-left list-disc pl-5">
              <li>Sign Up for users and businesses to manage your own profiles.</li>
              <li>Profile Cards with social media links and contact information will be back.</li>  
              <li>Automatic Country and Province detection (we're expanding!)</li>            
              <li>Personalized Business Profile Pages.</li>
              <li>Users can now favorite and follow businesses.</li>
              <li>Business Dashboard to view analytics, engage with followers, and offer exclusive deals.</li>
            </ul>
          </div>
        </div>
        <div className="items-center px-4 py-3 mt-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-[#F7CD65] text-black text-base font-medium rounded-md w-full shadow-sm hover:bg-[#e2a145] focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            Got it, thanks!
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeModal;