import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { supabase } from '../config/supabaseClient';

const PLACES_BATCH_SIZE = 500;
const CACHE_EXPIRATION = 1000 * 60 * 60; // 1 hour

export const usePlaces = (initialFilters) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [totalPlaces, setTotalPlaces] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sortOption, setSortOption] = useState(searchParams.get('sort') || 'recent');
  const [filters, setFilters] = useState(initialFilters);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchCategories = useCallback(async () => {
    try {
      const cachedCategories = localStorage.getItem('categories');
      const cacheTimestamp = localStorage.getItem('categoriesCacheTimestamp');
      
      if (cachedCategories && cacheTimestamp && Date.now() - parseInt(cacheTimestamp) < CACHE_EXPIRATION) {
        setCategories(JSON.parse(cachedCategories));
      } else {
        const { data, error } = await supabase
          .from('categories')
          .select('*')
          .order('name');
        if (error) throw error;
        setCategories(data);
        localStorage.setItem('categories', JSON.stringify(data));
        localStorage.setItem('categoriesCacheTimestamp', Date.now().toString());
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }, []);

  const fetchSubCategories = useCallback(async (categoryId) => {
    try {
      const cachedSubCategories = localStorage.getItem(`subCategories_${categoryId}`);
      const cacheTimestamp = localStorage.getItem(`subCategoriesCacheTimestamp_${categoryId}`);
      
      if (cachedSubCategories && cacheTimestamp && Date.now() - parseInt(cacheTimestamp) < CACHE_EXPIRATION) {
        setSubCategories(JSON.parse(cachedSubCategories));
      } else {
        const { data, error } = await supabase
          .from('subcategories')
          .select('*')
          .eq('cat_id', categoryId)
          .order('name');
        if (error) throw error;
        setSubCategories(data);
        localStorage.setItem(`subCategories_${categoryId}`, JSON.stringify(data));
        localStorage.setItem(`subCategoriesCacheTimestamp_${categoryId}`, Date.now().toString());
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  }, []);

  const fetchPlaces = useCallback(async (currentFilters, currentOffset = 0, reset = false, currentSortOption = sortOption) => {
    setLoading(reset);
    setLoadingMore(!reset);
    try {
      let query = supabase.from('places').select('*', { count: 'exact' });

      if (currentFilters.province) {
        query = query.ilike('province', `%${currentFilters.province}%`);
      }
      if (currentFilters.city) query = query.ilike('city', `%${currentFilters.city}%`);
      if (currentFilters.category) {
        console.log('Applying category filter:', currentFilters.category);
        query = query.eq('category', currentFilters.category);
      }
      if (currentFilters.subCategory) query = query.eq('sub_category', currentFilters.subCategory);

      switch (currentSortOption) {
        case 'recent':
          query = query.order('created_at', { ascending: false });
          break;
        case 'a-z':
          query = query.order('name');
          break;
        case 'z-a':
          query = query.order('name', { ascending: false });
          break;
        default:
          query = query.order('created_at', { ascending: false });
      }

      console.log('Executing query with filters:', currentFilters);
      const { data, error, count } = await query.range(currentOffset, currentOffset + PLACES_BATCH_SIZE - 1);

      if (error) throw error;

      console.log(`Fetched ${data.length} places, total count: ${count}`);

      // Log information about coordinates
      const placesWithCoordinates = data.filter(place => place.lng && place.lat);
      console.log(`Places with valid coordinates: ${placesWithCoordinates.length} out of ${data.length}`);

      if (reset) {
        setPlaces(data);
        setTotalPlaces(count || 0);
      } else {
        setPlaces(prevPlaces => {
          const newPlaces = [...prevPlaces];
          data.forEach(place => {
            const index = newPlaces.findIndex(p => p.id === place.id);
            if (index === -1) {
              newPlaces.push(place);
            } else {
              newPlaces[index] = place;
            }
          });
          return newPlaces;
        });
      }
      setOffset(currentOffset + PLACES_BATCH_SIZE);
      setHasMore((count || 0) > currentOffset + PLACES_BATCH_SIZE);

    } catch (error) {
      setError('Error fetching places');
      console.error('Error fetching places:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  }, [sortOption]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    fetchPlaces(filters, 0, true, sortOption);
  }, [fetchPlaces, filters, sortOption]);

  useEffect(() => {
    if (filters.category) {
      const selectedCategory = categories.find(cat => cat.name === filters.category);
      if (selectedCategory) {
        fetchSubCategories(selectedCategory.id);
      }
    } else {
      setSubCategories([]);
    }
  }, [filters.category, categories, fetchSubCategories]);

  const handleSort = useCallback((option) => {
    setSortOption(option);
    setOffset(0);
    setPlaces([]);
    
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('sort', option);
    setSearchParams(newSearchParams);

    fetchPlaces(filters, 0, true, option);
  }, [searchParams, setSearchParams, fetchPlaces, filters]);

  const loadMorePlaces = useCallback(() => {
    if (hasMore && !loadingMore) {
      fetchPlaces(filters, offset, false, sortOption);
    }
  }, [fetchPlaces, offset, filters, sortOption, hasMore, loadingMore]);

  const updateFilters = useCallback((newFilters) => {
    console.log('Updating filters:', newFilters);
    // Determine which filter was changed
    const changedFilter = Object.keys(newFilters).find(key => newFilters[key] !== filters[key]);
    console.log('Changed filter:', changedFilter);

    // If city is being removed, don't remove other filters
    if (changedFilter === 'city' && !newFilters.city) {
      newFilters = { ...filters, city: '' };
    }
    // If category is being removed, also remove subCategory
    else if (changedFilter === 'category' && !newFilters.category) {
      newFilters = { ...newFilters, subCategory: '' };
    }
    // If a new category is selected, remove subCategory
    else if (changedFilter === 'category' && newFilters.category !== filters.category) {
      newFilters = { ...newFilters, subCategory: '' };
    }

    console.log('Final new filters:', newFilters);

    setFilters(newFilters);
    setOffset(0);
    setPlaces([]); // Clear existing places
    setTotalPlaces(0); // Reset total places count

    // Update URL params
    const newSearchParams = new URLSearchParams();
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value) newSearchParams.set(key, value);
    });
    setSearchParams(newSearchParams);

    // Fetch new places with updated filters
    fetchPlaces(newFilters, 0, true, sortOption);
  }, [setSearchParams, fetchPlaces, sortOption, filters]);

  const filteredPlaces = useMemo(() => {
    return places.filter((place, index, self) =>
      index === self.findIndex((t) => t.id === place.id)
    );
  }, [places]);

  return {
    places: filteredPlaces,
    loading,
    loadingMore,
    error,
    totalPlaces,
    sortOption,
    fetchPlaces,
    handleSort,
    loadMorePlaces,
    updateFilters,
    categories,
    subCategories,
    filters,
    hasMore,
  };
};

export default usePlaces;